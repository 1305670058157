// React
import { memo, useState } from 'react';

// MUI Components
import {
  styled,
  Button,
  Box,
  Chip,
  Stack,
  Autocomplete,
  Divider,
  TextField,
  Typography,
} from '@mui/material';

// MUI Icons

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import Input from '../Input';
import GlobalPopper from 'components/Common/Popper';

import _ from 'lodash';
import PropTypes from 'prop-types';
import TimerControl from './TimerControl';

import TagsDropdown from '../TagsDropdown';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'transparent',
    '&.Mui-focused fieldset': {
      borderColor: '#5025c4',
      // boxShadow: '0 0 0 4px rgb(80 37 196 / 10%)',
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: 'auto',
  '&.MuiListSubheader-root': {
    color: 'rgb(242 32 118)',
    lineHeight: '32px',
    backgroundColor: '#f0f0f0',
  },
  flex: 1,
});

function ActiveTimer({
  timer,
  inputPlaceholder,
  containerProps,
  tasksDatasource,
  partnersDatasource,
  campaignsDatasource,
  conceptsDatasource,
  tagsDatasource,
  canStopTimer,
  requiredFields,
  onPresetsSelectionChange,
  onPartnersSelectionChange,
  onCampaignsSelectionChange,
  onConceptsSelectionChange,
  selectedTaskCategory,
  selectedPartner,
  selectedCampaign,
  selectedConcept,
  onStartTimer,
  onStopTimer,
  onAddTags,
  onClosePopper,
  isSmartly,
  handleTagsList,
  tagsList,
  handleExistingOnRemoveAllTags,
}) {
  // React state
  const [selectionsPopperAnchorEl, setSelectionsPopperAnchorEl] =
    useState(null);

  const [isSelectionsPopperOpen, setIsSelectionsPopperOpen] = useState(false);
  const [isTagsPopperOpen] = useState(false);

  // // TODO: These are redundant state. Can be improved by using the state of the parent.
  // const [selectedTaskCategory, setSelectedTaskCategory] = useState(null);
  // const [selectedPartner, setSelectedPartner] = useState(null);
  // const [selectedCampaign, setSelectedCampaign] = useState(null);
  // const [selectedConcept, setSelectedConcept] = useState(null);

  // // Hooks
  // useEffect(() => {
  //   if (!_.isEmpty(timer) && _.isNull(selectedTaskCategory)) {
  //     dispatch(setSelectedTaskCategory(timer.category));
  //   }
  // }, [timer]);

  // Handlers
  const handleSelectionsButtonClick = (e) => {
    setSelectionsPopperAnchorEl(e.currentTarget);
    setIsSelectionsPopperOpen(!isSelectionsPopperOpen);
    !_.isEmpty(timer) && handleTagsList(timer);
  };

  const handleOnClosePopper = (_) => {
    setIsSelectionsPopperOpen(isTagsPopperOpen ? true : false);
    !_.isEmpty(timer) && onClosePopper(timer);
  };

  // const handleOnClickAddTags = (e) => {
  //   setTagsPopperAnchorEl(e.currentTarget);
  //   setIsTagsPopperOpen(!isTagsPopperOpen);
  // };

  const handleOnStartTimer = () => {
    onStartTimer();
  };

  const handleOnStopTimer = () => {
    if (timer) {
      // dispatch(setSelectedTaskCategory(null));
      // dispatch(setSelectedPartner(null));
      // dispatch(setSelectedCampaign(null));
      // dispatch(setSelectedConcept(null));
      onStopTimer(timer);
    }
  };

  const filteredConceptsBySelectedPartner = () => {
    return selectedPartner || (timer?.partner ?? null)
      ? conceptsDatasource.filter(
          (c) =>
            (selectedPartner?.uuid ??
              selectedPartner?.id ??
              timer?.partner.id) === c.partner_uuid
        ) ?? []
      : conceptsDatasource ?? [];
  };

  const filteredCampaignsBySelectedConcept = () => {
    return selectedConcept || (timer?.concept ?? null)
      ? campaignsDatasource.filter(
          (c) =>
            (selectedConcept?.uuid ??
              selectedConcept?.id ??
              timer?.concept.uuid) === c.concept_id
        ) ?? []
      : campaignsDatasource ?? [];
  };

  return (
    <Stack spacing={1} {...containerProps}>
      <Stack direction="row" justifyContent="space-between" m={1} spacing={2}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ width: '-webkit-fill-available' }}
        >
          {/* Task selections */}
          <Input
            data={tasksDatasource ?? []}
            placeholder={inputPlaceholder}
            value={{
              name: selectedTaskCategory?.name ?? timer?.category?.name,
            }}
            onSelectionChange={(data) => {
              onPresetsSelectionChange(data, timer);
            }}
          />
        </Stack>

        {/* Project Selections */}
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            sx={{
              minWidth: '5rem',
              whiteSpace: 'nowrap',
            }}
          >
            <Button
              sx={{
                textTransform: 'none',
                '& .MuiButton-startIcon': {
                  marginRight: '4px',
                  '& .MuiSvgIcon-root': { fontSize: '14px' },
                },
              }}
              startIcon={
                _.isNull(timer?.partner ?? null) &&
                _.isNull(selectedPartner) && <AddIcon />
              }
              color="secondary"
              onClick={handleSelectionsButtonClick}
            >
              {selectedPartner?.name ?? 'Partner'}
            </Button>
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ borderStyle: 'dashed' }}
          />
          <TimerControl
            canStopTimer={canStopTimer}
            onStart={handleOnStartTimer}
            onStop={handleOnStopTimer}
          />
        </Stack>

        {/* Concept, Campaigns, Partners Selection */}
        <GlobalPopper
          isOpen={isSelectionsPopperOpen}
          anchorEl={selectionsPopperAnchorEl}
          onClose={handleOnClosePopper}
          onOp
          placement={'bottom'}
          sx={{ zIndex: 1 }}
          content={
            <Stack p={3} minWidth={350}>
              {/* Partner */}
              <Stack
                spacing={-0.5}
                mb={requiredFields?.includes('partner') ? 1 : 2}
              >
                <StyledAutocomplete
                  disablePortal
                  //freeSolo
                  forcePopupIcon={false}
                  isOptionEqualToValue={(option, value) =>
                    option.uuid ?? option.id === value.id ?? value.uuid
                  }
                  // value={selectedPartner ?? timer?.partner}
                  value={selectedPartner}
                  options={
                    partnersDatasource.filter(
                      (data) => data.uuid != 'uncat001'
                    ) ?? []
                  }
                  getOptionLabel={(option) => option.name}
                  onChange={(_, value) => {
                    onPartnersSelectionChange(value);
                    onConceptsSelectionChange(null);
                    onCampaignsSelectionChange(null);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      // Prevent's default 'Enter' behavior.
                      event.defaultMuiPrevented = true;
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      size="small"
                      label="Select a partner"
                      placeholder={'Select a partner'}
                      required={requiredFields?.includes('partner')}
                    />
                  )}
                  loading={false}
                  disabled={_.isNull(selectedTaskCategory)}
                />
                {requiredFields?.includes('partner') && (
                  <Typography
                    pl={0.5}
                    variant="span"
                    color="secondary"
                    fontSize={11}
                  >
                    This field is required
                  </Typography>
                )}
              </Stack>

              {/* Concept */}
              <Stack
                spacing={-0.5}
                mb={requiredFields?.includes('concept') ? 0 : 2}
              >
                <StyledAutocomplete
                  disablePortal
                  //freeSolo
                  forcePopupIcon={false}
                  loading={false}
                  isOptionEqualToValue={(option, value) =>
                    option.uuid === value.id ?? value.uuid
                  }
                  // value={selectedConcept ?? timer?.concept ?? null}
                  value={selectedConcept}
                  options={filteredConceptsBySelectedPartner()}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, value) => {
                    onConceptsSelectionChange(value);
                    onCampaignsSelectionChange(null);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      // Prevent's default 'Enter' behavior.
                      event.defaultMuiPrevented = true;
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      size="small"
                      label={
                        _.isEmpty(filteredConceptsBySelectedPartner())
                          ? 'No Concepts Available'
                          : 'Select a concept'
                      }
                      placeholder={'Select a concept'}
                      required={requiredFields?.includes('concept')}
                    />
                  )}
                  disabled={
                    _.isEmpty(filteredConceptsBySelectedPartner()) ||
                    _.isNull(selectedTaskCategory) ||
                    isSmartly
                  }
                />
                {requiredFields?.includes('concept') && (
                  <Typography
                    pl={0.5}
                    variant="span"
                    color="secondary"
                    fontSize={11}
                  >
                    This field is required
                  </Typography>
                )}
              </Stack>

              {/* Campaign */}
              <Stack spacing={-0.5} mb={2}>
                <StyledAutocomplete
                  disablePortal
                  //freeSolo
                  forcePopupIcon={false}
                  loading={false}
                  isOptionEqualToValue={(option, value) =>
                    option.uuid === value.id ?? value.uuid
                  }
                  // value={selectedCampaign ?? timer?.campaign}
                  value={selectedCampaign}
                  options={filteredCampaignsBySelectedConcept()}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, value) => {
                    onCampaignsSelectionChange(value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      // Prevent's default 'Enter' behavior.
                      event.defaultMuiPrevented = true;
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      size="small"
                      label={
                        _.isEmpty(filteredCampaignsBySelectedConcept())
                          ? 'No Campaigns Available'
                          : 'Select a campaign'
                      }
                      placeholder={'Select a campaign'}
                      required={requiredFields?.includes('campaign')}
                    />
                  )}
                  disabled={
                    _.isEmpty(filteredCampaignsBySelectedConcept()) ||
                    _.isNull(selectedTaskCategory) ||
                    isSmartly
                  }
                />
                {/* <VirtualizedSelection /> */}
                {requiredFields?.includes('campaign') && (
                  <Typography
                    pl={0.5}
                    variant="span"
                    color="secondary"
                    fontSize={11}
                  >
                    This field is required
                  </Typography>
                )}
              </Stack>

              <TagsDropdown
                datasource={_.isEmpty(timer) ? tagsDatasource : tagsList}
                onClickItem={onAddTags}
                onEnter={() => {}}
              />

              {/* Tags */}
              <Box width="fit-content" maxWidth={500}>
                {tagsDatasource && _.isEmpty(timer)
                  ? tagsDatasource
                      .filter((i) => i.is_selected)
                      .map((e, i) => (
                        <Chip
                          color="secondary"
                          key={i}
                          label={e.title}
                          size="small"
                          variant="outlined"
                          sx={{
                            marginRight: '0.5em',
                            cursor: 'pointer',
                          }}
                        />
                      ))
                  : tagsList
                      .filter((i) => i.is_selected)
                      .map((e, i) => (
                        <Chip
                          color="secondary"
                          key={i}
                          label={e.title}
                          size="small"
                          variant="outlined"
                          sx={{
                            marginRight: '0.5em',
                            cursor: 'pointer',
                          }}
                        />
                      ))}

                {(_.isEmpty(timer) &&
                  !_.isEmpty(
                    tagsDatasource?.filter((tags) => tags.is_selected)
                  )) ||
                (!_.isEmpty(timer) &&
                  !_.isEmpty(tagsList?.filter((tags) => tags.is_selected))) ? (
                  <Chip
                    icon={<DeleteIcon />}
                    label="Clear tags"
                    color="secondary"
                    size="small"
                    variant="outlined"
                    sx={{
                      marginRight: '0.5em',
                      cursor: 'pointer',
                      borderStyle: 'dashed',
                      '& .MuiChip-iconSmall': {
                        width: '0.7em',
                        marginLeft: '5px',
                      },
                    }}
                    onClick={() => {
                      handleExistingOnRemoveAllTags({
                        taskTimerId: timer?.task_timer_id,
                      });
                    }}
                  />
                ) : null}
              </Box>
            </Stack>
          }
        />

        {/* Tags Selection*/}
        {/* <GlobalPopper
          isOpen={isTagsPopperOpen}
          anchorEl={tagsPopperAnchorEl}
          onClose={() => setIsTagsPopperOpen(false)}
          placement="top"
          sx={{ zIndex: 1 }}
          content={
            <ListAddSelection
              taskId={null}
              type={'tags'}
              relType={'task'}
              defaultData={tagsDatasource}
              handleSave={onAddTags}
            />
          }
        /> */}
      </Stack>
    </Stack>
  );
}

ActiveTimer.propTypes = {
  timer: PropTypes.any,
  inputPlaceholder: PropTypes.any,
  containerProps: PropTypes.any,
  tasksDatasource: PropTypes.any,
  partnersDatasource: PropTypes.any,
  campaignsDatasource: PropTypes.any,
  conceptsDatasource: PropTypes.any,
  tagsDatasource: PropTypes.any,
  requiredFields: PropTypes.any,
  canStopTimer: PropTypes.any,
  onStartTimer: PropTypes.any,
  onStopTimer: PropTypes.any,
  onAddTags: PropTypes.any,
  onClosePopper: PropTypes.any,
  onPresetsSelectionChange: PropTypes.any,
  onPartnersSelectionChange: PropTypes.any,
  onCampaignsSelectionChange: PropTypes.any,
  onConceptsSelectionChange: PropTypes.any,
  selectedTaskCategory: PropTypes.any,
  selectedPartner: PropTypes.any,
  selectedCampaign: PropTypes.any,
  selectedConcept: PropTypes.any,
  isSmartly: PropTypes.bool,
  handleTagsList: PropTypes.func,
  tagsList: PropTypes.any,
  handleExistingOnRemoveAllTags: PropTypes.func,
};

export default memo(ActiveTimer);
