import { createSlice } from '@reduxjs/toolkit';
import {
  fetchUserTimeLogsRequest,
  fetchActiveTimerRequest,
  fetchCategoriesRequest,
  fetchCategoriesWithRequiredFieldsRequest,
  startTimerRequest,
  stopRunningTimerRequest,
  updateTimerRequest,
  deleteTimerRequest,
  requestGetPromptTimer,
  requestContinuePromptTimer,
  fetchSmartlyPartnersRequest,
} from 'services/api/timer';
import {
  requestAddTags,
  requestRemoveTags,
  requestFetchTags,
} from 'services/api/tasks';
import _ from 'lodash';
import { requestActiveTaskTimers } from 'services/api/tasks';

const initialState = {
  categories: [],
  logs: [],
  active: {},
  adweavePartners: [],
  smartlyPartners: [],
  campaigns: [],
  concepts: [],
  prompt: {},
  taskTimers: [],
  tagsList: [],
  isUpdatingTimer: false,
  isFetching: false,
  isFetchingTags: false,
  isFetchingTaskTimers: false,
  isFetchingActiveTimer: false,
  isFetchingWithPagination: false,
  isFetchingCategoriesWithRequiredFields: false,
  error: null,
};

const timer = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    reset: () => initialState,
    initPresetCategories: (state) => {
      state.error = null;
    },
    initCategoriesWithRequiredFields: (state) => {
      state.error = null;
      state.isFetchingCategoriesWithRequiredFields = true;
    },
    initTimeLogs: (state, { payload }) => {
      state.isFetching = payload;
      state.error = null;
    },
    initPaginatedTimeLogs: (state, { payload }) => {
      state.isFetchingWithPagination = payload;
      state.error = null;
    },
    initActiveTimer: (state) => {
      state.isFetchingActiveTimer = true;
    },
    initTaskTimers: (state) => {
      state.isFetchingTaskTimers = true;
    },
    initTagList: (state) => {
      state.isFetchingTags = true;
      state.tagsList = [];
    },
    startTimerStart: (state) => {
      state.isUpdatingTimer = true;
    },
    stopTimerStart: (state) => {
      state.isUpdatingTimer = true;
    },
    initPresetCategoriesSuccess: (state, { payload }) => {
      state.list = payload;
    },
    initCategoriesWithRequiredFieldsSuccess: (state, { payload }) => {
      state.categories = payload?.task_categories ?? [];
      state.adweavePartners = payload?.partner_groups ?? [];
      state.campaigns = payload?.campaigns ?? [];
      state.concepts = payload?.concepts ?? [];
      state.isFetchingCategoriesWithRequiredFields = false;
    },
    initSmartlyPartnersSuccess: (state, { payload }) => {
      state.smartlyPartners = payload.data;
    },
    initTimelogSuccess: (state, { payload }) => {
      state.logs = payload;
      state.error = null;
      state.isFetching = false;
    },
    initPaginatedTimelogSuccess: (state, { payload }) => {
      state.isFetchingWithPagination = false;
      state.logs = [...(state.logs ?? []), ...payload];
      state.error = null;
    },
    initActiveTimerSuccess: (state, { payload }) => {
      state.active = payload;
      state.error = null;
      state.isFetchingActiveTimer = false;
    },
    initTaskTimersSuccess: (state, { payload }) => {
      state.taskTimers = payload;
      state.error = null;
      state.isFetchingTaskTimers = false;
    },
    initPartnersSuccess: (state, { payload }) => {
      state.adweavePartners = payload;
      state.error = null;
    },
    initCampaignsSuccess: (state, { payload }) => {
      state.campaigns = payload;
      state.error = null;
    },
    initConceptsSuccess: (state, { payload }) => {
      state.concepts = payload;
      state.error = null;
    },
    startTimerSuccess: (state, { payload }) => {
      state.isUpdatingTimer = false;
      state.active = payload;
    },
    stopTimerSuccess: (state) => {
      state.isUpdatingTimer = false;
      state.active = {};
    },
    deleteTimerSuccess: () => {},
    getPromptTimerSuccess: (state, { payload }) => {
      state.prompt = payload;
    },
    initTagListSuccess: (state, { payload }) => {
      state.isFetchingTags = false;
      state.tagsList = payload;
    },
    initUpdateTagSuccess: (state, { payload }) => {
      state.tagsList = payload;
    },
    initPresetCategoriesFailed: (state, { payload }) => {
      state.error = payload;
      state.list = [];
    },
    initCategoriesWithRequiredFieldsFailed: (state, { payload }) => {
      state.error = payload;
      state.categories = [];
      state.isFetchingCategoriesWithRequiredFields = false;
    },
    initTimelogsFailed: (state, { payload }) => {
      state.error = payload;
      state.isFetching = false;
    },
    initActiveTimerFailed: (state, { payload }) => {
      state.error = payload;
    },
    initTaskTimersFailed: (state, { payload }) => {
      state.error = payload;
      state.isFetchingTaskTimers = false;
    },
    initPartnersFailed: (state, { payload }) => {
      state.error = payload;
    },
    initCampaignsFailed: (state, { payload }) => {
      state.error = payload;
    },
    initConceptsFailed: (state, { payload }) => {
      state.error = payload;
    },
    startTimerFailed: (state, { payload }) => {
      state.error = payload;
      state.isUpdatingTimer = false;
    },
    stopTimerFailed: (state, { payload }) => {
      state.error = payload;
      state.isUpdatingTimer = false;
    },
    deleteTimerFailed: (state, { payload }) => {
      state.error = payload;
    },
    updateTimerFailed: (state, { payload }) => {
      state.error = payload;
      state.isUpdatingTimer = false;
    },
    TagListFailed: (state, { payload }) => {
      state.error = payload;
      state.isFetchingTags = false;
    },
    updateTagFailed: (state, { payload }) => {
      state.error = payload;
    },
    tagListFailed: (state, { payload }) => {
      state.error = payload;
      state.isFetchingTags = false;
    },
  },
});

export const {
  reset,
  initTimeLogs,
  initPaginatedTimeLogs,
  initActiveTimer,
  initTaskTimers,
  initPresetCategories,
  initCategoriesWithRequiredFields,
  initTagList,
  initPresetCategoriesSuccess,
  initCategoriesWithRequiredFieldsSuccess,
  initSmartlyPartnersSuccess,
  initTaskTimersSuccess,
  startTimerStart,
  stopTimerStart,
  initTimelogSuccess,
  initPaginatedTimelogSuccess,
  initActiveTimerSuccess,
  initPartnersSuccess,
  initCampaignsSuccess,
  initConceptsSuccess,
  startTimerSuccess,
  stopTimerSuccess,
  deleteTimerSuccess,
  getPromptTimerSuccess,
  initTagListSuccess,
  initUpdateTagSuccess,
  initPresetCategoriesFailed,
  initCategoriesWithRequiredFieldsFailed,
  initTimelogsFailed,
  initActiveTimerFailed,
  initPartnersFailed,
  initCampaignsFailed,
  initConceptsFailed,
  initTaskTimersFailed,
  startTimerFailed,
  stopTimerFailed,
  updateTimerFailed,
  deleteTimerFailed,
  updateTagFailed,
  tagListFailed,
} = timer.actions;

// fetch task datasource
export const fetchCategories = () => async (dispatch) => {
  dispatch(initPresetCategories());

  const { success, data, message } = await fetchCategoriesRequest();

  success
    ? dispatch(initPresetCategoriesSuccess(data.data))
    : dispatch(initPresetCategoriesFailed(message));
};

export const fetchCategoriesWithRequiredFields = () => async (dispatch) => {
  dispatch(initCategoriesWithRequiredFields());

  const { success, data, message } =
    await fetchCategoriesWithRequiredFieldsRequest();

  success
    ? dispatch(initCategoriesWithRequiredFieldsSuccess(data))
    : dispatch(initCategoriesWithRequiredFieldsFailed(message));
};

export const fetchSmartlyPartners = () => async (dispatch) => {
  const { success, data } = await fetchSmartlyPartnersRequest();

  success && dispatch(initSmartlyPartnersSuccess(data));
};

// fetch user time logs
export const fetchUserTimeLogs =
  (userId, pagination, onSuccess, shouldShowLoader = true) =>
  async (dispatch) => {
    const wantsToLoadFirstPage =
      _.isNull(pagination.page) || (pagination.page ?? 1) === 1;

    wantsToLoadFirstPage
      ? dispatch(initTimeLogs(shouldShowLoader))
      : dispatch(initPaginatedTimeLogs(shouldShowLoader));

    const { success, data, message } = await fetchUserTimeLogsRequest(
      userId,
      pagination
    );

    if (success) {
      _.isNull(pagination.page) || (pagination.page ?? 1) === 1
        ? dispatch(initTimelogSuccess(data))
        : dispatch(initPaginatedTimelogSuccess(data));
      onSuccess();
    } else {
      dispatch(initTimelogsFailed(message));
    }
  };

// fetch active timer
export const fetchActiveTimer = () => async (dispatch) => {
  dispatch(initActiveTimer());

  const { success, data, message } = await fetchActiveTimerRequest();

  success
    ? dispatch(initActiveTimerSuccess(data))
    : dispatch(initActiveTimerFailed(message));
};

// start timer
export const startTimerById = (params) => async (dispatch) => {
  dispatch(startTimerStart());

  const { success, data, message } = await startTimerRequest(params);

  success
    ? dispatch(startTimerSuccess(data))
    : dispatch(startTimerFailed(message));
};
// stop timer
export const stopTimerById =
  (userId, params, pagination, onReset = () => {}) =>
  async (dispatch) => {
    dispatch(stopTimerStart());

    const { success, message } = await stopRunningTimerRequest(params);

    if (success) {
      dispatch(stopTimerSuccess());
      onReset();
      !_.isNull(pagination) &&
        dispatch(fetchUserTimeLogs(userId, pagination, () => {}, false));
    } else {
      dispatch(startTimerFailed(message));
    }
  };

// update timer
export const updateTimer = (userId, params, pagination) => async (dispatch) => {
  const { success, message } = await updateTimerRequest(params);

  if (success) {
    pagination &&
      dispatch(fetchUserTimeLogs(userId, pagination, () => {}, false));
  } else {
    dispatch(updateTimerFailed(message));
  }
};

// delete timer
export const deleteTimer =
  (userId, params, pagination, onSuccess) => async (dispatch) => {
    const { success, message } = await deleteTimerRequest(params);

    if (success) {
      dispatch(deleteTimerSuccess());
      dispatch(fetchUserTimeLogs(userId, pagination, onSuccess, false));
    } else {
      dispatch(deleteTimerFailed(message));
    }
  };

export const getPromptTimer =
  (onSuccess = () => {}) =>
  async (dispatch) => {
    const { success, data } = await requestGetPromptTimer();
    if (success) {
      dispatch(getPromptTimerSuccess(data));
      onSuccess();
    }
  };

export const continuePromptTimer =
  (params, onSuccess = () => {}) =>
  async () => {
    const { success } = await requestContinuePromptTimer(params);
    if (success) {
      onSuccess();
    }
  };

export const fetchTaskTimers =
  (onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(initTaskTimers());
    const { success, data, message } = await requestActiveTaskTimers();
    if (success) {
      dispatch(initTaskTimersSuccess(data));
      onSuccess();
    } else {
      dispatch(initTaskTimersFailed(message));
    }
  };

export const fetchTags = (params) => async (dispatch) => {
  dispatch(initTagList());
  const { success, data, message } = await requestFetchTags(params);

  success ? dispatch(initTagListSuccess(data)) : tagListFailed(message);
};

export const updateTags = (params) => async (dispatch) => {
  const { success, data, message } =
    params.action == 'add'
      ? await requestAddTags(params)
      : await requestRemoveTags(params);

  if (success) {
    dispatch(initUpdateTagSuccess(data));
    // pagination &&
    //   dispatch(fetchUserTimeLogs(userId, pagination, () => {}, false));
  } else {
    dispatch(updateTagFailed(message));
  }
};

export default timer.reducer;
