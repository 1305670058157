import { memo } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import useRouteGuard from 'hooks/useRouteGuard';
import { useSelector } from 'react-redux';
// MUI Components
import { Box } from '@mui/material';
// Components
import Header from 'components/Common/Header';
//styles
import { headerMargin } from 'theme/variables';
import { useStyles } from 'app/styles';

const AuthenticatedRoutes = ({ component: Component, ...rest }) => {
  const classes = useStyles();
  /* Temporary Disabled Prompt Timer  */
  //const dispatch = useDispatch();

  /* Temporary Disabled Prompt Timer  */
  //const { prompt } = useSelector((state) => state.timer);
  const { data: userData } = useSelector((state) => state.user);
  /* Temporary Disabled Prompt Timer  */
  //const { pathname } = useLocation();

  useRouteGuard();

  /* Temporary Disabled Prompt Timer  */
  // useEffect(() => {
  //   dispatch(getPromptTimer());
  // }, [pathname]);

  // useEffect(() => {
  //   if (!_.isNull(prompt) && !_.isEmpty(prompt)) {
  //     setTimeout(() => {
  //       if (!Swal.isVisible()) showPrompt();
  //     }, 1000);
  //   }
  // }, [prompt]);

  // const showPrompt = () => {
  //   Swal.fire({
  //     iconHtml:
  //       '<img src="https://cdn.dribbble.com/users/1519658/screenshots/3501765/running-clock-02.gif" style="width: 150px">',
  //     title:
  //       "We've noticed your task timer is greater than 5 hours.\n Would you like to stop your task timer?",
  //     background: '#f0f6f7',
  //     showDenyButton: true,
  //     showCancelButton: false,
  //     confirmButtonText: 'Stop',
  //     denyButtonText: 'Cancel',
  //     focusConfirm: true,
  //   }).then((result) => {
  //     const id = prompt[0].id;
  //     const type = prompt[0].type;
  //     const isFromTask = type === 'task';

  //     if (result.isConfirmed) {
  //       if (isFromTask) {
  //         dispatch(stopTimer(null, { id, from_prompt: 1 }));
  //       }
  //       // else {
  //       //   dispatch(stopTimerById(null, { id, from_prompt: 1 }, null));
  //       // }
  //     } else {
  //       dispatch(continuePromptTimer({ id, type }));
  //     }
  //   });
  // };

  return (
    <Box width="100%" className={classes.overflowHiddenX} height="100vh">
      <Header />
      <Route
        {...rest}
        render={(props) => {
          return (
            <Box
              width={'100%'}
              style={{ marginTop: !userData?.first_login ? headerMargin : 0 }}
            >
              <Box>
                <Component {...props} />
              </Box>
            </Box>
          );
        }}
      />
    </Box>
  );
};

AuthenticatedRoutes.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default memo(AuthenticatedRoutes);
