import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  requestDashboardStatusCount,
  requestDashboardResources,
  requestDashboardTimelog,
  requestDashboardFilters,
  requestDashboardTicket,
  requestDashboardTicketCount,
} from 'services/api/dashboard';

import { requestUsers } from 'services/api/user';

import { requestStatus } from 'services/api/status';

import { requestPriorityFlag } from 'services/api/maintenance';

import { requestUpdateKey } from 'services/api/updateKey';

import {
  requestTicketPriorityFlag,
  requestTicketStatus,
  requestUpdateAssignees,
  requestUpdatePriority,
  requestUpdateStatus,
} from 'services/api/ticket';

import { requestUpdateTaskByKey } from 'services/api/tasks';

import { formatDate } from 'utils/date';

import { initTimeLogs } from './timer';

const initialState = {
  statusCount: {},
  totalTime: {},
  dashboard: {},
  resources: {},
  members: [],
  statuses: [],
  priorities: [],
  fetchUpdateKey: false,
  fetchStatusCount: false,
  fetchTotalTime: false,
  fetchDashboard: false,
  fetchResources: false,
  fetchMembers: false,
  fetchStatuses: false,
  fetchPriorities: false,
  errorUpdateKey: null,
  errorStatusCount: null,
  errorTotalTime: null,
  errorDashboard: null,
  errorResources: null,
  errorMembers: null,
  errorStatuses: null,
  errorPriorities: null,
  fetchRepullDashboard: false,
};

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    initDashboard: (state) => {
      state.fetchDashboard = true;
      state.errorDashboard = null;
    },
    initRepullDashboard: (state) => {
      state.fetchRepullDashboard = true;
      state.errorDashboard = null;
    },
    initStatusCount: (state) => {
      state.fetchStatusCount = true;
      state.errorStatusCount = null;
    },
    initTotalTime: (state) => {
      state.fetchTotalTime = true;
      state.errorTotalTime = null;
    },
    initResources: (state) => {
      state.fetchResources = true;
      state.errorResources = null;
    },
    initMembers: (state) => {
      state.fetchMembers = true;
      state.errorMembers = null;
    },
    initStatuses: (state) => {
      state.fetchStatuses = true;
      state.errorStatuses = null;
    },
    initPriorities: (state) => {
      state.fetchPriorities = true;
      state.errorPriorities = null;
    },
    successDashboard: (state, { payload }) => {
      state.dashboard = payload;
      state.fetchDashboard = false;
      state.errorDashboard = null;
    },
    successRepullDashboard: (state, { payload }) => {
      state.dashboard = payload;
      state.fetchRepullDashboard = false;
      state.errorDashboard = null;
    },
    successTotalTime: (state, { payload }) => {
      state.totalTime = payload;
      state.fetchTotalTime = false;
      state.errorTotalTime = null;
    },
    successStatusCount: (state, { payload }) => {
      state.statusCount = payload;
      state.fetchStatusCount = false;
      state.errorStatusCount = null;
    },
    successResources: (state, { payload }) => {
      state.resources = payload;
      state.fetchResources = false;
      state.errorResources = null;
    },
    initUpdateKey: (state) => {
      state.fetchUpdateKey = true;
      state.errorUpdateKey = null;
    },
    successMembers: (state, { payload }) => {
      state.members = {
        ...payload,
        data: payload.data.map((i) => ({ ...i, user_id: i.id })),
      };
      state.fetchMembers = false;
      state.errorMembers = null;
    },
    successStatuses: (state, { payload }) => {
      state.statuses = payload;
      state.fetchStatuses = false;
      state.errorStatuses = null;
    },
    successPriorities: (state, { payload }) => {
      state.priorities = payload;
      state.fetchPriorities = false;
      state.errorPriorities = null;
    },
    successUpdateKey: (state, { payload }) => {
      const {
        params1: { id, key },
        response, // api response
      } = payload;

      switch (key) {
        case 'task_status':
          return {
            ...state,
            dashboard: {
              ...state?.dashboard,
              all_tasks: {
                ...state?.dashboard?.all_tasks,
                data: state?.dashboard?.all_tasks?.data?.map((task) =>
                  task?.id === id
                    ? {
                        ...task,
                        status: _.startCase(response.status.replace('_', ' ')),
                        status_id: response.status_id,
                      }
                    : task
                ),
              },
            },
          };

        case 'task_priority':
          return {
            ...state,
            dashboard: {
              ...state?.dashboard,
              all_tasks: {
                ...state?.dashboard?.all_tasks,
                data: state?.dashboard?.all_tasks?.data?.map((task) =>
                  task?.id === id
                    ? {
                        ...task,
                        priority: response.priority,
                        priority_id: response.priority_id,
                      }
                    : task
                ),
              },
            },
          };

        case 'task_due_date':
          return {
            ...state,
            dashboard: {
              ...state?.dashboard,
              all_tasks: {
                ...state?.dashboard?.all_tasks,
                data: state?.dashboard?.all_tasks?.data?.map((task) =>
                  task?.id === id
                    ? {
                        ...task,
                        due_date: response.due_date,
                      }
                    : task
                ),
              },
            },
          };

        case 'task_delivery_date':
          return {
            ...state,
            dashboard: {
              ...state?.dashboard,
              all_tasks: {
                ...state?.dashboard?.all_tasks,
                data: state?.dashboard?.all_tasks?.data?.map((task) =>
                  task?.id === id
                    ? {
                        ...task,
                        delivery_date: response.delivery_date,
                      }
                    : task
                ),
              },
            },
          };

        case 'task_assignees':
          return {
            ...state,
            dashboard: {
              ...state?.dashboard,
              all_tasks: {
                ...state?.dashboard?.all_tasks,
                data: state?.dashboard?.all_tasks?.data?.map((task) =>
                  task?.id === id
                    ? {
                        ...task,
                        assignees: response,
                      }
                    : task
                ),
              },
            },
          };

        case 'ticket_priority':
          return {
            ...state,
            dashboard: {
              ...state?.dashboard,
              data: state?.dashboard?.data?.map((ticket) =>
                ticket?.id === id
                  ? {
                      ...ticket,
                      priority: response,
                    }
                  : ticket
              ),
            },
          };

        case 'ticket_assignees':
          return {
            ...state,
            dashboard: {
              ...state?.dashboard,
              data: state?.dashboard?.data?.map((ticket) =>
                ticket?.id === id
                  ? {
                      ...ticket,
                      assignee: response,
                    }
                  : ticket
              ),
            },
          };
        case 'ticket_status':
          return {
            ...state,
            dashboard: {
              ...state?.dashboard,
              data: state?.dashboard?.data?.map((ticket) =>
                ticket?.id === id
                  ? {
                      ...ticket,
                      status: {
                        ...state?.dashboard?.data?.status,
                        id: response,
                      },
                    }
                  : ticket
              ),
            },
          };

        default:
          break;
      }
    },
    successUpdateDashboard: (state, { payload }) => {
      const assignees = payload.assignees.map((data) => ({
        user_id: data.id,
        username: data.name,
        avatar: data.avatar,
      }));

      return {
        ...state,
        dashboard: {
          ...state?.dashboard,
          all_tasks: {
            ...state?.dashboard?.all_tasks,
            data: state?.dashboard?.all_tasks?.data?.map((task) =>
              task?.id === payload.id
                ? {
                    ...task,
                    due_date: payload.due_date,
                    delivery_date: payload.delivery_date,
                    status: _.startCase(payload.status?.replace(/_/g, ' ')),
                    status_id: payload.status_id,
                    priority: _.isEqual(payload.priority_description, 'Normal')
                      ? 'Medium'
                      : _.startCase(payload.priority_description),
                    priority_id: payload.priority_id,
                    assignees: assignees,
                  }
                : task
            ),
          },
        },
      };
    },
    errorDashboard: (state, { payload }) => {
      state.errorDashboard = { message: payload };
      state.fetchDashboard = false;
    },
    errorTotalTime: (state, { payload }) => {
      state.fetchTotalTime = false;
      state.errorTotalTime = { message: payload };
    },
    errorStatusCount: (state, { payload }) => {
      state.fetchStatusCount = false;
      state.errorStatusCount = { message: payload };
    },
    errorResources: (state, { payload }) => {
      state.fetchResources = false;
      state.errorResources = { message: payload };
    },
    errorUpdateKey: (state, { payload }) => {
      state.fetchUpdateKey = false;
      state.errorUpdateKey = { message: payload };
    },
    resetDashboard: (state) => {
      state.dashboard = initialState.dashboard;
    },
    reset: () => initialState,
  },
});

export const {
  initDashboard,
  initStatusCount,
  initTotalTime,
  initResources,
  initRepullDashboard,
  initMembers,
  initStatuses,
  initPriorities,
  initUpdateKey,
  successDashboard,
  successStatusCount,
  successTotalTime,
  successResources,
  successRepullDashboard,
  successMembers,
  successStatuses,
  successPriorities,
  successUpdateKey,
  errorUpdateKey,
  errorDashboard,
  errorStatusCount,
  errorTotalTime,
  errorResources,
  errorMembers,
  errorStatuses,
  errorPriorities,
  successUpdateDashboard,
  resetDashboard,
  reset,
} = dashboard.actions;

export const getDashboardTasks =
  (page = 1, limit = 20, params, type, teamId) =>
  async (dispatch) => {
    type === 'repull'
      ? dispatch(initRepullDashboard())
      : dispatch(initDashboard());

    if (teamId != 11) {
      const { success, data, message } = await requestDashboardFilters(
        page,
        limit,
        params
      );
      success
        ? type === 'repull'
          ? dispatch(successRepullDashboard(data))
          : dispatch(successDashboard(data))
        : dispatch(errorDashboard(message));
    } else {
      const success = true;
      const { data: all_tasks, message } = await requestDashboardTicket(
        page,
        limit,
        params?.filter,
        params?.sort.toString()
      );
      const { data: count } = await requestDashboardTicketCount(params?.filter);

      const data = {
        ..._.first(count),
        all_tasks: all_tasks ?? [],
      };

      success
        ? type === 'repull'
          ? dispatch(successRepullDashboard(data))
          : dispatch(successDashboard(data))
        : dispatch(errorDashboard(message));
    }
  };

export const getStatusCounter = (teamdId) => async (dispatch) => {
  dispatch(initStatusCount());

  const { success, data, message } = await requestDashboardStatusCount(teamdId);

  success
    ? dispatch(successStatusCount(teamdId == 11 ? data[0] : data))
    : dispatch(errorStatusCount(message));
};

export const getResources = () => async (dispatch) => {
  dispatch(initResources());

  const { success, data, message } = await requestDashboardResources();

  success
    ? dispatch(successResources(data))
    : dispatch(errorResources(message));
};

export const getMembers = () => async (dispatch) => {
  dispatch(initMembers());

  const { success, message, data } = await requestUsers();

  success ? dispatch(successMembers(data)) : dispatch(errorMembers(message));
};

export const getStatuses = (type, teamId) => async (dispatch) => {
  dispatch(initStatuses());

  const { success, message, data } =
    teamId != 11 ? await requestStatus(type) : await requestTicketStatus();

  if (success) {
    teamId == 11
      ? dispatch(
          successStatuses(
            _.flatMap(data, (data, key) => {
              return { id: Number(key), name: data };
            })
          )
        )
      : dispatch(successStatuses(data));
  } else dispatch(errorStatuses(message));
};

export const getPriorities = (teamId) => async (dispatch) => {
  dispatch(initPriorities());

  const { success, data, message } =
    teamId != 11
      ? await requestPriorityFlag()
      : await requestTicketPriorityFlag();

  if (success) {
    teamId == 11
      ? dispatch(
          successPriorities(
            _.flatMap(data, (data, key) => {
              return { id: key, name: data };
            })
          )
        )
      : dispatch(successPriorities(data));
  } else dispatch(errorPriorities(message));
};

export const getDashboardTotalTime = (teamdId) => async (dispatch) => {
  dispatch(initTimeLogs());

  const { success, data, message } = await requestDashboardTimelog(teamdId);
  success
    ? dispatch(successTotalTime(data))
    : dispatch(errorTotalTime(message));
};

export const updateGlobal =
  (params1, params2, onFailure = () => {}) =>
  async (dispatch) => {
    dispatch(initUpdateKey());

    const { success, data, message } = [
      'task_status',
      'task_due_date',
      'task_delivery_date',
    ].includes(params1.key)
      ? await requestUpdateTaskByKey({
          ...params1,
          is_parent: params1?.is_parent ? 1 : 0,
          key: params1.key.replace('task_', ''),
          value:
            params1.key === 'task_status'
              ? params1.value
              : formatDate(params1.value, 'MM/DD/yyyy hh:mm:ss A'),
        })
      : await requestUpdateKey(params1);

    if (success)
      dispatch(successUpdateKey({ params1, params2, response: data ?? {} }));
    else {
      dispatch(errorUpdateKey(message));
      onFailure(message);
    }
  };

export const updateTicketGlobal = (params1, params2) => async (dispatch) => {
  dispatch(initUpdateKey());

  const { success, data, message } = await setData(params1?.key, params1);

  success
    ? dispatch(successUpdateKey({ params1, params2, response: data ?? {} }))
    : dispatch(errorUpdateKey(message));
};

const setData = (type, params) => {
  switch (type) {
    case 'ticket_priority':
      return requestUpdatePriority({ id: params?.id, priority: params?.value });
    case 'ticket_assignees':
      return requestUpdateAssignees({ id: params?.id, user_id: params?.value });
    case 'ticket_status':
      return requestUpdateStatus({ id: params?.id, status: params?.value });
  }
};

export default dashboard.reducer;
