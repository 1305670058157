export const headerMargin = '50px';

export const appColors = {
  black: '#323338',
  darkGray: '#666666',
  gray: '#C4C4C4',
  lightGray: '#e0e0e075',
  lighterGray: '#F5F6F8',
  lightPink: '#DF3C763D',
  green: '#0F9D58',
  favorited: '#FFB946',
  violet: '#A03DC1',
  lightViolet: '#5025c4',
  status: {
    notStarted: '#666666',
    onHold: '#DD2C00',
    completed: '#84C529',
    complete: '#84C529',
    inProgress: '#FFAB00',
    testing: '#a56161',
    awaitingFeedback: '#00bcd4',
    forTesting: '#a56161',
    forHandover: '#95B9C7',
    inCs: '#66CDAA',
    inDesign: '#F67280',
    inProduction: '#191970',
    inQa: '#00A36C',
    withClient: '#931314',
    clientReview: '#FF5733',
    delivered: '#492EBC',
    live: '#F22076',
    active: '#84C529',
    inactive: '#666666',
    onTrack: '#84C529',
    critical: '##f1c40f',
    overdue: '#DD2C00',
    delayed: '#DD2C00',
    open: '#666666',
    assigned: '#FFAB00',
    inConsult: '#a56161',
    answered: '#95B9C7',
    finished: '#84C529',
  },
  social: {
    facebook: '#5887FF',
    google: '#4BDE97',
    youtube: '#F26464',
  },
  priority: {
    urgent: '#FF0000',
    high: '#f39c12',
    normal: '#2ecc71',
    medium: '#492EBC',
    low: '#95a5a6',
  },
  dashboard: {
    health: {
      onTrackHover: '#4022762e',
      criticalHover: '#ffb648',
      overdueHover: '#f16079',
      onTrack: 'inherit',
      critical: '#ffb6488c',
      overdue: '#f160793b',
      completed: '#5C52C3',
    },
    error: '#5C52C3',
    warning: '#4BDE97',
    success: '#ffb648',
    default: '#f26464',
    secondary: '#F22076',
  },
};
